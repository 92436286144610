import { Thing } from '@al-ko/types';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ThingMapper } from '../../mappers/thing.mapper';
import { MetaDataModel } from '../../../core/domain/metadata.type';
// import { Result } from './../../../core/types/types';
// import { BookmarkRequest } from 'src/app/data/requests/bookmark.request';

@Injectable({ providedIn: 'root' })
export abstract class IDeviceInteractor {
  abstract getDeviceList(): Observable<Thing[]>;
  abstract getDevice(thingName: string): Observable<Thing>;
  abstract subscribeToDevice(thingName: string): Observable<any>;
  abstract unsubscribeToDevice(thingName: string): void;
  abstract getDeviceMetadata(thingName: string): Observable<MetaDataModel[]>;
  abstract createDeviceMetadata(thingName: string): Observable<any>;

  // Do i really wasnt this to be part of the contracte/interface?
  abstract getCachedDevice(thingName: string): Thing;
}
